
const overlayObj = '<div class="drawer-menu-overlay" id="overlay"></div>';

document.body.insertAdjacentHTML( 'beforeend', overlayObj );



document.addEventListener('DOMContentLoaded', function() {

  const burger = document.getElementById('burger');

  burger.addEventListener('click', function(){

    document.body.classList.toggle('open-menu');

  });

  // オーバーレイをクリックしたらメニューを消す
  const overlay = document.getElementById('overlay');

  overlay.addEventListener('click', function(){

    document.body.classList.remove('open-menu');

  });

});
